import React, { useState, useEffect } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { CountryPagePropsType, lang, Region } from '../../entities/queriesTypes'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { useGlobalStateContext } from '../../Context/site'
import translations from '../../entities/translations';
import PageSection from '../../components/organism/HomePageComponent';
import {Card} from '../../components/molecule/Card'
import { buildImageFocalPoint } from '../../utils';
import { countrySubMenu } from '../../utils/subNavigation'
import PortableTextRenderer from "../../components/sanityBlockContents/PortableTextRenderer";

const IndexPage:React.FC<CountryPagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const pageHash = translations.countryMenuID;
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.country,
    subNavigationItem: countrySubMenu })
  const { state } = useGlobalStateContext();
  const buttonTransaltion = translations.globals.buttons;
  const pageID = translations.globals.subMenuTranslation;
  const _regionTranslation = translations.CountryPages.find((item) => item.menu === 'Orte')!;
  const [showReadMore, setShowReadMore] = useState<boolean>(true)
  const [countryRegions, setCountryRegions] = useState<Region [] | undefined>(undefined)


  useEffect(() => {
    if (page?.region?.length && state?.lang) {
      page?.region.sort((a, b) => a?.title?.[state?.lang! as lang]?.localeCompare(b?.title?.[state?.lang! as lang]));
      const _regions = [...page?.region]
      if (_regions.length < 3) {
        setCountryRegions(_regions)
      } else {
        _regions.length = 3
        setCountryRegions(_regions)
      }
    }
  }, [page?.region, state?.lang])

  const handleReadMore = () => {
    setCountryRegions(page?.region)
    setShowReadMore(false)
  }

  return (
    <Layout seoTitle={(page?.metadata?.page_title && page?.metadata?.page_title[state?.lang as lang]) as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="pt-12" id={pageHash?.Orte?.[state?.lang as lang]!}>
      {page?.regionsIntro?.[state?.lang as lang]
        && <PortableTextRenderer body={page?.regionsIntro?.[state?.lang as lang]} />}
      </Wrapper>

      <PageSection
        sanityContext={null}
        id={``}
        showReadMore={showReadMore}
        onClick={handleReadMore}
        idTitle={pageID.Orte[state?.lang as lang]!}
        title={''} >
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
         {countryRegions?.map((item, i: number) => <Card
          uniqueRef={item?.['_id']!}
          key={i}
          _className="mx-2 sm:w-full md:w-full lg:w-full mb-4"
          imageAlt={(item?.regionImage?.alt && item?.regionImage?.alt[state?.lang as lang]) || ''}
          ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug && 
            item?.parentCountry?.slug[state?.lang as lang]?.current}/${_regionTranslation.slug[state?.lang as lang]}/${item?.slug 
              && item?.slug[state?.lang as lang]?.current}#${pageHash?.Orte?.[state?.lang as lang]!}`}
          imageUrl={buildImageFocalPoint(item.regionImage?.image)}
          title={(item?.title && item?.title[state?.lang as lang]) || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="REGION"
          bodyText={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
          cardSize={'SMALL'}
          /> )}
        </div>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryRegionPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryRegionsImage(resolveReferences: {maxDepth: 2})
      regionsIntro:_rawRegionsIntro(resolveReferences: {maxDepth: 10})
      metadata: _rawCountryRegionsMetadata
      region: regions {
        _id
        regionImage {
          alt: _rawAlt(resolveReferences: {maxDepth: 10})
          image: _rawImage(resolveReferences: {maxDepth: 10})
        }
        slug {
          de {
            current
          }

        }
        title {
          de
         
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
        cardTextShort {
          de
         
        }
      }
    }
  }
`
